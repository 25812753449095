@import '../../styles/AppDesign.scss';

.PieChart {
  position: relative;
  height: 400px;
  max-height: 400px;
  background-color: $white;
  border-radius: 1rem;
  box-shadow: $GenericBoxShadow;
  overflow: hidden;
  padding: 1rem 0;

  * {
    font-family: $font-family;
  }

  &-loading {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: -1px;
    left: 0;
    bottom: 0;
    right: 0;

    .react-loading-skeleton {
      width: 100%;
      height: 100%;
    }
  }
}
