@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.AlertModal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 20;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    padding: 0 24px;
    text-align: center;
    margin: 2rem 0;

    &-description {
      margin: 0;
      color: $TextColor;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }

    &-errorMessage {
      color: $ErrorRed;
      font-size: 14px;
      margin: 0px;
    }
  }

  &-buttonsRow {
    flex: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    & .cta-button {
      flex: 1;
      max-width: 200px;

      &:not(:first-child) {
        margin-left: 12px;
      }
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
}

@include mobile {
  .AlertModal {
    &-content {
      padding: 0;
    }
    &-buttonsRow {
      flex-direction: column;
      .cta-button {
        margin: 0 !important;
        width: 100%;
        max-width: unset;

        &:first-of-type {
          margin-bottom: 0.5rem !important;
        }
      }
    }
  }

  .ReactModal {
    &__Content {
      &--after-open {
        inset: 0 !important;
        width: 75vw !important;
        padding: 10vw 5vw !important;
      }
    }
  }
}
