@import '../../styles/AppDesign.scss';

.InputTextField {
  display: flex;
  flex-direction: column;
  width: 100%;

  input,
  textarea {
    background-color: $white;
    height: 45px;
    border-radius: 10px;
    padding: 0 15px;
    box-shadow: 0px 0px 10px 0 rgba($black, 0.02);
    font-size: 15px;
    border: 1px solid $BorderGray;

    &[type='date'],
    &[type='date-time'] {
      width: 100%;
      box-sizing: border-box;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px $white inset;

      &:first-line {
        font-size: 15px;
      }
    }

    &::placeholder {
      color: $SubTextColor;
    }

    &:focus {
      border: 1px solid $MainCyan;
    }

    &:disabled {
      background-color: $DisabledInputField;
      color: $SubTextColor;
    }
  }

  textarea {
    resize: vertical;
    padding: 15px;
    min-height: 80px;
    max-height: 160px;
    box-sizing: border-box;

    &:focus {
      outline: none;
      border-color: 1px solid $MainCyan;
    }
  }

  &-input {
    &-label {
      font-weight: 600;
      font-size: 15px;
      padding-bottom: 10px;

      &-bottom {
        &-error {
          padding-bottom: 6px;
          font-size: 14px;
          color: $ErrorRed;
        }
      }
    }

    &-error {
      border: 1px solid $ErrorRed !important;
    }

    &-disabled {
      background-color: $DisabledInputField !important;
      color: rgb(95, 95, 95);
    }
  }
}
