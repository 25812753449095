$max-width-desktop: 1180px;
$screen-md-max: 991px;
$screen-sm-max: 575px;
$screen-sm-min: 320px;

// Max device (large desktop, >1180px)
@mixin desktop-and-up {
  @media screen and (min-width: $max-width-desktop + 1px) {
    @content;
  }
}

// Large device (small desktop, 992px - 1180px)
@mixin desktop {
  @media screen and (max-width: $max-width-desktop) {
    @content;
  }
}

// Md device (tablet, 576px - 991px)
@mixin tablet {
  @media screen and (max-width: $screen-md-max) {
    @content;
  }
}

// Small device (mobile, 0px = 575px)
@mixin mobile {
  @media screen and (max-width: $screen-sm-max) {
    @content;
  }
}

// Extra small device (mobile, 0 - 320px)
@mixin small-mobile {
  @media screen and (max-width: $screen-sm-min) {
    @content;
  }
}

// Print specific
@mixin print {
  @media print {
    @content;
  }
}

@mixin mobile-and-print {
  @include mobile {
    @content;
  }
  @include print {
    @content;
  }
}
