@import './styles/AppDesign.scss';

html,
body {
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

html {
  background-color: $bgGray;
  display: flex;
  align-items: center;
}

input,
button {
  outline: none;
  -webkit-appearance: none;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $TextColor;
  -webkit-overflow-scrolling: touch;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

a {
  color: $TextColor;
  text-decoration: none;
}

.highcharts-credits {
  display: none;
}
