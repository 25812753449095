@import '../../styles/AppDesign.scss';

.PhoneInputWrapper {
  position: relative;

  &-input {
    padding-left: 70px !important;
  }

  &-prefix {
    position: absolute;
    left: 15px;
    bottom: 13px;
    display: flex;
    flex-direction: row;

    &.mod-error {
      bottom: 19px;
    }

    &.mod-error-message {
      bottom: 36px;
    }

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }

    span {
      color: $SubTextColor;
      font-size: 14px;
      padding-top: 1px;
      padding-left: 12px;
    }
  }
}
