@import '../../styles/AppDesign.scss';

.ContextMenu {
  position: absolute;
  z-index: 999;
  right: 0px;
  width: auto;
  min-width: 100px;
  height: auto;
  margin-top: 2%;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.4) 0px 6px 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-item {
    width: 100%;
    padding: 14px 20px;
    box-sizing: border-box;
    color: $TextGray;
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    border: solid 1px $bgGray;
    border-right: none;
    border-left: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    white-space: nowrap;

    &:hover,
    &.mod-active {
      background-color: rgba($MainCyan, 0.1);
      color: $MainCyan;
    }

    &-icon {
      width: 14px;
      height: 20px;
      margin-left: 14px;
      background-color: $TextDarkerGray;
    }
  }
}
