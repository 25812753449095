@import '~styles/AppDesign.scss';
@import '~styles/media-queries.scss';

.BodyMeasurementRecord {
  &-field-name {
    &-label {
      font-weight: 600;
      font-size: 15px;
      padding-bottom: 10px;
    }

    &-subfields {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -6px;

      & > * {
        flex: 1 0 200px;
        padding: 0 6px;

        .InputTextField-input-label {
          font-weight: 300;
          font-size: 12px;
        }
      }
    }
  }

  &-subsection {
    padding-bottom: 24px;

    &-title {
      font-weight: 700;
      font-size: 15px;
    }

    &-content {
      padding: 24px 0px 0px 12px;

      .InputTextField,
      .InputDropdownField {
        margin-bottom: 12px;
      }

      .InputTextField-input-label {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  &-radio {
    &-with-input {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @include mobile {
        .InputTextField,
        .InputDropdownField,
        .DatePicker {
          &-input-label {
            height: 6px;
          }
        }
      }
    }
  }

  &-input {
    &-date-picker {
      input {
        width: 140px;
        background-color: $white;
        height: 45px;
        border-radius: 10px;
        padding: 0 15px;
        box-shadow: 0px 0px 10px 0 rgba($black, 0.02);
        font-size: 15px;
        border: 1px solid $BorderGray;
        margin-bottom: -6px;
      }
    }
  }
}
