@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';

.SSPViewAllUsers {
  flex-direction: column;
  overflow: hidden;
  padding-top: 1.5rem !important;
}

@include tablet {
  .SSPViewAllUsers {
    padding-top: 0 !important;
  }
}
