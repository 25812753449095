@import '../../../../../styles/AppDesign.scss';
@import '../../../../../styles/media-queries.scss';

.SendFormsAndDocuments-select-patient {
  &-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &-tag-list {
    padding: 0 2rem;
    margin-top: 1.5rem;
    max-height: 90px;
    overflow-y: scroll;
  }
}

@include mobile {
  .SendFormsAndDocuments-select-patient {
    &-tag-list {
      padding: 0 1rem;
    }
  }
}
