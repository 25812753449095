// Colors
$black: #000000;
$white: #ffffff;
$bgGray: #f1f5f7;
$MainLightCyan: #92e8ee;
$MainCyan: #06bbc9;
$TextColor: #0d2238;
$TextDarkerGray: #777f88;
$TextGray: #9ba6b2;
$LightGray: #caced1;
$LighterGray: #ddd;
$SubTextColor: hsl(211, 13%, 65%);
$BorderGray: #f6f8fb;
$ErrorRed: #a50203;
$NavGray: #e6eaed;
$SelectedCyan: #11a3b5;
$DarkCyan: #01838d;
$UnselectedCyan: #ecf4f5;
$TrueGreen: #7abd91;
$FalseRed: #ff6962;
$ErrorRed: #ea4949;
$DisabledInputField: #ebebeb;
$PastelPink: #e6a99f;
$PastelCyan: #76ada8;
$PastelGreen: #a6b889;

// Box Shadow
$GenericSoftBoxShadow: rgba(149, 157, 165, 0.05) 0px 8px 24px;
$GenericBoxShadow: rgba(149, 157, 165, 0.15) 0px 8px 24px;
$GenericInsetBoxShadow: inset rgb(149 157 165 / 25%) 0 0px 6px 0px;

// Default font family
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;
