@import '../../../../../../styles/AppDesign.scss';
@import '../../../../../../styles/media-queries.scss';

.AppointmentDetails {
  display: flex;
  flex-direction: column;
  &-toolbar {
    display: flex;
    flex-direction: row-reverse;
    button {
      padding: 0 2rem;
      margin-left: 1rem;
    }
  }
  &-container {
    box-shadow: $GenericInsetBoxShadow;
    flex: 1;
    overflow-y: scroll;
  }

  &-section-container {
    display: flex;
    justify-content: space-between;
    flex: 1;

    &-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  &-section {
    display: flex;
    flex-direction: column;
    width: 48.5%;

    h1 {
      color: $DarkCyan;
      font-size: 1.5rem;
      border-bottom: 1px solid $LightGray;
      padding-bottom: 1rem;
      margin: 0;
    }

    &-information {
      width: 50%;
      overflow-y: scroll;

      &-content {
        &-row {
          margin-bottom: 1rem;
          display: flex;
          span {
            word-break: break-word;

            &:first-of-type {
              text-transform: capitalize;
              min-width: 190px;
              font-weight: bold;
            }

            &:last-of-type {
              user-select: text;
            }
          }

          &-value {
            &-remarks {
              width: 100%;
              border: 1px solid $LighterGray;
              border-radius: 10px;
              padding: 1rem;

              p {
                &:first-of-type {
                  margin-top: 0;
                }
              }
            }

            &-anchor {
              color: $MainCyan;
            }
          }
        }
      }
    }
  }
}

@include tablet {
  .AppointmentDetails {
    min-height: unset;
    &-toolbar {
      .cta-button {
        &:last-of-type {
          margin-left: 0;
        }
      }
    }

    &-section {
      width: 100%;
      margin-bottom: 1rem;

      &-container {
        flex-direction: column;
      }
    }
  }
}

@include mobile {
  .AppointmentDetails {
    padding: 1rem;
    &-toolbar {
      flex-direction: column-reverse;
      width: 100%;
      .cta-button {
        // margin-bottom: 1rem;

        width: 100%;
        margin-left: 0;

        &:last-of-type {
          margin-bottom: 0.5rem;
        }
      }
    }

    &-section {
      width: 100%;
      margin-bottom: 1rem;

      &-container {
        flex-direction: column;
      }

      &-information {
        &-content {
          &-row {
            span {
              &:first-of-type {
                min-width: 100px;
                max-width: 100px;
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
