@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

@mixin columnStyle {
  word-wrap: break-word;
  flex: 1;
  box-sizing: border-box;
  padding: 12px 2rem;
  line-height: 1.5rem;

  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 0px;
  }
}

.Table {
  flex: 1;
  display: flex;
  flex-direction: column;

  &-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #f5f7f9;
    padding: 0 2rem;
    box-sizing: border-box;

    &-column {
      @include columnStyle;
      color: $TextGray;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &-loading-rows {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &-body {
    flex: 1;

    &-row {
      box-sizing: border-box;
      padding: 0 2rem;

      &:hover,
      &.mod-active {
        background-color: rgba($MainCyan, 0.1);
        color: $MainCyan;
      }

      &-content {
        height: 100%;
        box-sizing: border-box;
        border-bottom: solid 1px $bgGray;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        &-column {
          @include columnStyle;

          &.mod-boolean {
            @mixin booleanStyle {
              text-align: center;
              color: $white;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
            }

            &-true {
              @include booleanStyle;
              background-color: $TrueGreen;
            }
            &-false {
              @include booleanStyle;
              background-color: $FalseRed;
            }
          }

          &.mod-link {
            color: $SelectedCyan;
          }
        }
      }
    }
  }
}
