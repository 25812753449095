@import '../../../../../styles/AppDesign.scss';

.SendFormsAndDocuments-review-container {
  padding-top: 2rem;
  height: unset;
  flex: 1;

  &-accordions {
    box-shadow: $GenericInsetBoxShadow;
  }
}
