@import '../../styles/AppDesign.scss';

.Accordion {
  &-title-container {
    display: flex;
    align-items: center;
    width: 100%;

    &-title {
      font-size: 1.2rem;
      font-weight: 600;
      font-family: $font-family;
      color: $DarkCyan;
    }

    &-subtitle {
      margin-left: auto;
      margin-right: 1rem;
      font-size: 1rem;
      color: $TextGray;
    }
  }

  // ==================== Mui Styling ====================
  &.MuiAccordion-root {
    &:before {
      background-color: transparent;
    }
    box-shadow: none;
    background-color: rgba($MainCyan, 0.05);

    .MuiAccordionSummary-root {
      border-bottom: 1px solid rgba($MainCyan, 1);
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    * {
      fill: $DarkCyan;
    }
  }

  .MuiAccordionDetails-root {
    padding: 1rem;
    border-color: $LighterGray !important;
    background-color: $white;
    border-bottom: 1px solid $LighterGray;

    .MuiTypography-root {
      font-size: 1rem;
      font-weight: normal;
      color: $TextColor;
    }
  }
}
