@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.login {
  &-container {
    display: flex;
    flex: 1;
    overflow-x: hidden;

    &-left {
      position: relative;
      width: 50%;
      padding-left: 2.5%;
      padding-right: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: 2.5rem;
        letter-spacing: 0cm;
        font-weight: 650;
        margin-bottom: 1rem;

        span {
          color: $MainCyan;
        }
      }

      h2 {
        font-size: 1rem;
        margin-bottom: 3.5rem;
        margin-top: 0;
        color: $SubTextColor;
        font-weight: normal;

        &.register-tip {
          margin-bottom: 0;
          position: absolute;
          bottom: 3rem;
          line-height: 2.5rem;
        }
      }
    }

    &-right {
      position: relative;
      width: 60%;
      overflow-x: hidden;

      &-bg-img {
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100vw;
        position: absolute;
        z-index: 2;
        background: #0d1c1d;
        -webkit-mask-image: linear-gradient(-90deg, $black 55%, transparent);

        &.lowRes {
          z-index: 1;
        }
      }
    }
  }

  &-error {
    padding-left: 0.3rem;
    margin-top: 1.5rem;
    color: $ErrorRed;
    opacity: 0;

    &.mod-show {
      opacity: 1;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-container {
      display: flex;
      width: 100%;

      .InputTextField {
        &:first-of-type {
          margin-right: 1rem;
        }
      }
    }

    .cta-button {
      margin-top: 1.5rem;
      width: 150px;
      border-radius: 75px;
    }
  }
}

@include tablet {
  @media screen and (orientation: landscape) {
    .login {
      &-container {
        &-left {
          justify-content: flex-start;
        }
      }
    }
  }
}

@include tablet {
  .login {
    &-container {
      padding: 1rem;
      &-left {
        padding-bottom: 1rem;
        padding: 0;
        width: 100%;

        h1 {
          width: 100%;
          font-size: 2.2rem;
        }

        h2 {
          line-height: 1.5;
          margin-bottom: 2rem;

          &.register-tip {
            margin-top: 1rem;
            position: unset;
            line-height: 1.5;
            padding-bottom: 1rem;
          }

          .ApplicationVersion {
            margin-top: 1rem;
          }
        }
      }

      &-right {
        display: none;
      }
    }
    &-form {
      .cta-button {
        margin-top: 0;
      }
    }
  }
}

@include mobile {
  .login {
    &-container {
      &-left {
        height: 100%;
      }
    }

    &-form {
      &-container {
        flex-direction: column;

        .InputTextField {
          &:first-of-type {
            margin-bottom: 1rem;
          }
        }
      }
    }

    &-error {
      margin: 1.2rem 0;
    }
  }
}
