@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.TagList {
  display: flex;
  flex-wrap: wrap;

  &-item {
    background-color: rgba($MainCyan, 0.1);
    margin-bottom: 1rem;
    margin-right: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $MainCyan;
    font-weight: 500;
    height: 35px;

    &.mod-clickable {
      cursor: pointer;
      &:hover {
        background-color: $MainLightCyan;
      }
    }

    &-btn {
      mask: url('/assets/icons/cross.svg') no-repeat center;
      background-color: $MainCyan;
      mask-size: contain;
      mask-position: center;
      width: 20px;
      height: 100%;
      border: none;
      margin-left: 0.7rem;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      &.mod-add {
        mask: url('/assets/icons/plus.svg') no-repeat center;
        mask-size: 95%;
        margin-left: 0.4rem;
      }
    }
  }
}

@include mobile {
  .TagList {
    justify-content: space-between;

    &-item {
      flex: calc(0.5);
      margin-right: 0.3rem;
      max-width: calc(50% - 2.3rem);

      &:nth-child(2n) {
        margin-left: 0.3rem;
        margin-right: 0;
      }
    }
  }
}
