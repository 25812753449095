@import '~styles/AppDesign.scss';

.MedicareDMEPOSSupplierStandardForm {
  &-emphasized-statement {
    font-weight: bold;
    line-height: 1.4;

    &.mod-after {
      padding-bottom: 30px;
    }
  }

  &-description {
    & ol {
      line-height: 1.4;
      padding-left: 30px;
    }
  }
}
