@import '../../../../../styles/AppDesign.scss';
@import '../../../../../styles/media-queries.scss';

.CreateAppointment-review {
  &-container {
    padding: 0 2rem;
    margin-top: 2rem;
    box-shadow: $GenericInsetBoxShadow;
    flex: 1;
    overflow-y: scroll;

    &-loading {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-section-container {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  &-section {
    display: flex;
    flex-direction: column;
    width: 48.5%;

    h1 {
      color: $DarkCyan;
      font-size: 1.5rem;
      border-bottom: 1px solid $LightGray;
      padding-bottom: 1rem;
    }

    &-information {
      width: 50%;
      overflow-y: scroll;

      &-content {
        &-row {
          margin-bottom: 1rem;
          display: flex;
          span {
            word-break: break-word;
            &:first-of-type {
              min-width: 190px;
              font-weight: bold;
            }

            &:last-of-type {
              user-select: text;
            }
          }

          &.mod-capitalize {
            span {
              line-height: 1.3;
              &:first-of-type {
                text-transform: capitalize;
              }

              a {
                color: $MainCyan;
              }
            }
          }

          &-value {
            &-remarks {
              width: 100%;
              border: 1px solid $LighterGray;
              border-radius: 10px;
              padding: 1rem;

              p {
                &:first-of-type {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include tablet {
  .CreateAppointment-review {
    &-container {
      padding: 0 1rem;
    }
    &-section-container {
      flex-direction: column;
    }

    &-section {
      width: 100%;

      h1 {
        margin-bottom: 0;
      }

      &-information {
        width: 100%;

        &-content {
          &-row {
            flex-direction: column;

            &-value {
              &-remarks {
                padding: 1rem 0;
                p {
                  padding: 0 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
