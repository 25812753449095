.SSPUsersMetrics {
  &-split {
    display: flex;

    &-item {
      width: 50%;

      &:nth-child(odd) {
        padding-right: 1.5rem;
      }
    }
  }
}
