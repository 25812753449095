@import '../../styles/AppDesign.scss';

.LanguageGlobe {
  &-btn {
    background: transparent;
    border: none;
    padding: 16px;
    box-sizing: border-box;
    height: 54px;
    margin-right: -16px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &-title {
      color: $TextGray;
      padding-right: 4px;
      font-size: 18px;
    }

    &-icon {
      display: block;
      width: 20px;
      height: 20px;
      background-color: $SubTextColor;
      mask: url('/assets/icons/globe.svg') no-repeat center;
      mask-size: contain;
    }
  }
}
