@import '../../../../../../../styles/AppDesign.scss';
@import '../../../../../../../styles/media-queries.scss';

.SendFormsAndDocuments-review-container {
  &-accordions {
    &-content {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .review {
        &-user-information {
          flex: 0.4;
          padding-right: 1rem;
          overflow-x: hidden;

          &-section-title {
            font-weight: bolder;
            font-size: 1.5rem;
            padding-top: 1rem;
          }

          &-hairline {
            margin-top: 1rem;
            margin-bottom: 2rem;
            background-color: $LighterGray;
            border: 0;
            height: 1px;
          }

          &-row {
            display: flex;
            padding-bottom: 1rem;

            span {
              word-break: break-word;
              &:first-of-type {
                font-weight: bold;
                padding-right: 1rem;
                white-space: nowrap;
                width: 100px;
                min-width: 100px;
              }

              &:last-of-type {
                user-select: text;
                word-break: break-word;
                a {
                  color: $SelectedCyan;
                }
              }
            }

            &-input-label-cta-link {
              color: $MainCyan;
            }
          }
        }

        &-previews {
          flex: 0.6;

          &-container {
            height: 400px;
            max-height: 400px;

            &-html,
            &-pdf {
              height: 100%;
              width: 100%;
              border-width: 0;
              padding-bottom: 1rem;
            }
          }

          &-cta-container {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 0;

            button {
              width: 49%;
            }
          }
        }
      }
    }
  }
}

@include tablet {
  @media screen and (orientation: landscape) {
    .SendFormsAndDocuments-review-container {
      &-accordions {
        &-content {
          flex-direction: column;
        }
      }
    }
  }
}

@include mobile {
  .SendFormsAndDocuments-review-container {
    &-accordions {
      &-content {
        flex-direction: column;
      }
    }
  }
}
