@import '~styles/AppDesign.scss';

.MeasurementInputGroup {
  display: flex;
  align-items: flex-end;

  &-number {
    flex: 1;
    max-width: 200px;
  }
  &-unit {
    flex: 1;
    margin-left: 12px;
    max-width: 200px;

    &.mod-dirty {
      select {
        color: $TextColor !important;
      }
    }
  }
}
