@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';

.ManageAppointmentTimeslots {
  &-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-custom-timeslot {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.2rem;

    .InputTextField-input {
      margin-bottom: 0 !important;
    }

    button {
      height: 47px;
      padding: 1rem 0;
      min-height: unset;
      margin-left: 1rem !important;
      margin-top: 0 !important;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;

    .InputTextField-input,
    .InputDropdownField-input {
      border-color: $NavGray;
      margin-bottom: 1.2rem;
    }

    .cta-button {
      width: 200px;
      margin: 0 auto;
      margin-top: auto;
    }
  }

  &-taglist {
    margin-bottom: 1rem;
    &-label {
      font-weight: 600;
      font-size: 15px;
      padding-bottom: 10px;
    }

    p {
      font-style: italic;
      color: $TextGray;
    }

    button {
      width: 50px;
    }
  }

  .cta-button {
    max-width: 100px;
  }
}

@include tablet {
  @media screen and (orientation: landscape) {
    .ManageAppointmentTimeslots {
      &-container {
        min-height: unset;
      }
    }
  }
}

@include mobile {
  .ManageAppointmentTimeslots {
    &-container {
      padding: 1rem;
      min-height: unset;
    }

    &-custom-timeslot {
      flex-direction: column;
      .cta-button {
        margin-left: 0 !important;
        margin: 0 auto !important;
        margin-top: 1rem !important;
      }
    }
  }
}
