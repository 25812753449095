@import '../../styles/AppDesign.scss';

.LangSwitchModal {
  &-list {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */

    &-item {
      color: $TextColor;
      font-size: 16px;
      text-align: center;
      border-bottom: solid 1px $BorderGray;

      &.mod-active {
        color: $MainCyan;
        font-weight: 500;
        background-color: $BorderGray;
      }

      &-button {
        color: inherit;
        font-size: inherit;
        background-color: transparent;
        border: none;
        width: 100%;
        padding: 24px 16px;
        cursor: pointer;
      }
    }
  }
}
