@import '~styles/AppDesign.scss';

.FormSection {
  padding-top: 12px;

  &:first-child {
    padding-top: 0;

    &-content {
      padding-top: 0;
    }
  }

  &:last-child {
    & > hr:last-child {
      display: none;
    }

    &-content {
      padding-bottom: 0px;
    }
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
  }

  &-content {
    padding: 12px 0px 12px;

    &.mod-inset-left {
      padding-left: 12px;
    }
    &.mod-inset-top {
      padding-top: 12px;
    }
    &.mod-inset-right {
      padding-right: 12px;
    }
    &.mod-inset-bottom {
      padding-bottom: 12px;
    }
  }
}
