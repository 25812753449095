@import '~styles/AppDesign.scss';

.PatientConsentForm {
  &-description {
    font-size: 16px;
    line-height: 1.4;

    & ul {
      padding-left: 20px;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-consentText {
      font-size: 16px;
      line-height: 1.4;
      padding: 14px 0px 18px;
    }

    &-remark {
      padding-top: 28px;
      padding-bottom: 6px;
      text-align: center;
      line-height: 1.4;
    }
  }
}
