@import '../../styles/AppDesign.scss';

.Reports {
  &-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-container {
    display: flex;
    flex-direction: column;
  }

  &-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1rem;

    &-content {
      display: flex;
      flex-direction: column;
      margin-right: auto;

      span {
        font-size: 1.3rem;
        font-weight: bold;

        &:last-of-type {
          margin-top: 1rem;
          font-size: 1rem;
          font-weight: normal;
          color: $MainCyan;
        }
      }
    }
  }
}
