@import '../../styles/AppDesign.scss';

.TableInfiniteScrollWrapper {
  &-loadMoreButton {
    width: 100%;
    height: 100%;
    appearance: none;
    border: none;
    background: transparent;
    color: $TextDarkerGray;
    font-size: 14px;
    cursor: pointer;

    &:disabled {
      cursor: progress;
    }
  }
}
