@import '../../styles/AppDesign.scss';

.NavigationTabs {
  &-tabs {
    height: 70px;
    min-height: 70px !important;
    background-color: $white;
    box-shadow: $GenericBoxShadow;
    z-index: 1;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    * {
      height: 100%;
      max-width: unset !important;
    }

    .MuiTabs-indicator {
      background-color: $MainCyan;
    }
  }

  &-tab {
    &.mod-flexAllTabs {
      &.MuiTab-root {
        min-width: unset;
        flex: 1;
      }
    }
    &.MuiTab-root {
      min-width: 50%;
      font-family: $font-family;

      font-size: 1rem;

      &.Mui-selected {
        font-weight: bold;
        color: $MainCyan;
      }
    }
  }
}
