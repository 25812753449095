@import '../../styles/AppDesign.scss';

.SignaturePadField {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-inputContainer {
    position: relative;

    &-clearButton {
      appearance: none;
      background-color: transparent;
      border: none;
      position: absolute;
      top: 10px;
      right: 0px;

      &-icon {
        display: block;
        width: 26px;
        height: 26px;
        mask: url('/assets/icons/x.svg') no-repeat center;
        mask-size: contain;
        background-color: $MainLightCyan;
      }
    }
  }

  &-input {
    background-color: $white;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 0px 10px 0 rgba($black, 0.02);
    border: 1px solid $BorderGray;

    &::placeholder {
      color: $SubTextColor;
    }

    &:focus {
      border: 1px solid $MainCyan;
    }

    &:disabled {
      background-color: #ebebeb;
    }

    &-label {
      font-weight: 600;
      font-size: 15px;
      padding-bottom: 10px;

      &-bottom {
        &-error {
          padding-bottom: 6px;
          font-size: 14px;
          color: #ea4949;
        }
      }
    }

    &-error {
      @extend .SignaturePadField-input;
      border: 1px solid #ea4949 !important;
    }
  }
}
