@import '../../styles/AppDesign.scss';
@import '../../styles//media-queries.scss';

.DashboardLayout {
  display: flex;
  height: 100%;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;

  &-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2%;
    max-width: 1800px;
    margin: 0 auto;
    overflow-x: hidden;

    &-heading {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &-toolbar {
    display: flex;
    margin-bottom: 2rem;
  }

  &-title {
    color: $TextColor;
  }

  &-subtitle {
    padding-top: 0.5rem;
    font-weight: normal;
    font-size: 1.2rem;
    color: $TextDarkerGray;
  }

  &-title,
  &-subtitle {
    margin: 0;

    &.mod-titleMargin {
      margin-bottom: 2rem;
    }
  }
}

@include tablet {
  .DashboardLayout {
    // padding-left: 48px;
    &-container {
      padding: 1rem;
      &-title {
        margin: 1rem 0;
        font-size: 1.5rem;
        &.mod-titleMargin {
          margin-bottom: 1rem !important;
        }
      }
      &-subtitle {
        font-size: 1.15rem;
      }
    }
  }

  @media screen and (orientation: landscape) {
    .DashboardLayout {
      &-container {
        &-heading {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}

@include mobile {
  .DashboardLayout {
    // width: calc(100% - 48px);
    height: 100%;
    position: absolute;
    overflow: scroll;

    &-toolbar {
      width: 100%;
      margin-bottom: 2rem;
      &-container {
        width: 100%;
      }
    }
    &-container {
      padding: 4%;

      &-heading {
        padding-top: 1rem;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
