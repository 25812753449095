@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.Card {
  display: flex;
  background-color: $white;
  border-radius: 1rem;
  box-shadow: $GenericBoxShadow;
  justify-self: center;
  padding: 2rem;
  flex: 1;

  &.mod-no-padding {
    padding: 0px;
    overflow: hidden;
  }
}

@include tablet {
  @media screen and (orientation: landscape) {
    .Card {
      min-height: 600px;
    }
  }
}
