@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.cta-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: $white;
  min-height: 55px;
  height: 55px;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 27.5px;
  border: 1px solid $white;
  background-origin: border-box;
  background-color: $MainCyan;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:active {
    background-color: $MainLightCyan;
  }

  img {
    margin-left: 0.5rem;
  }

  span {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.mod-fill {
    color: $MainCyan;
    background-color: $white;
  }

  &.mod-invert {
    border: 1px solid $MainCyan;
    background-color: transparent;
    color: $MainCyan;
    min-height: 54px;
    height: 54px;

    &:active {
      background-color: $bgGray;
    }
  }

  &.mod-disabled {
    opacity: 0.5;
    cursor: progress;
  }
}

@include tablet {
  .cta-button {
    height: 45px !important;
    min-height: 45px !important;
  }
}

@include mobile {
  .cta-button {
    height: 45px !important;
    min-height: 45px !important;
  }
}
