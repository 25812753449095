@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.UserProfileDetailsContainer {
  // Details Container
  &-details-container {
    display: flex;
    padding: 1rem 0.5rem;

    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
    }

    &-cell {
      display: flex;
      flex-direction: column;
      padding-right: 24px;
      padding-bottom: 8px;

      &:last-of-type {
        padding-right: 0;
      }

      span {
        word-wrap: break-word;

        &:last-of-type {
          font-weight: bold;
          user-select: text;
          cursor: text;
        }
      }
    }
  }
}
