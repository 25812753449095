@import '~styles/AppDesign.scss';
@import '~styles/media-queries.scss';

.FormHomeSafetyEnvironmentAssessment {
  p {
    line-height: 1.6;
  }

  &-radio {
    &-in-section {
      margin-bottom: 18px !important;

      &.mod-responsive-column {
        .RadioField-options {
          @include mobile {
            align-items: flex-start;
            flex-direction: column;
          }
        }
      }
    }

    &-with-input {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @include mobile {
        .InputTextField,
        .InputDropdownField,
        .DatePicker {
          &-input-label {
            height: 6px;
          }
        }
      }
    }
  }

  &-input {
    &-date-picker {
      input {
        width: 140px;
        background-color: $white;
        height: 45px;
        border-radius: 10px;
        padding: 0 15px;
        box-shadow: 0px 0px 10px 0 rgba($black, 0.02);
        font-size: 15px;
        border: 1px solid $BorderGray;
        margin-bottom: -6px;
      }
    }
  }

  &-section {
    &-optional-title {
      color: $SelectedCyan;
    }

    &-field-note {
      color: $TextGray;
    }

    .RadioField-label,
    .InputTextField-input-label {
      font-weight: 500 !important;
    }
  }
}
