@import '../../styles/AppDesign.scss';

.InputRtfTextareaField {
  &-label {
    font-weight: 600;
    font-size: 15px;
  }
  &-editor {
    margin-top: 10px;
    background-color: $white;
    border-radius: 10px;
    padding: 0 15px;
    box-shadow: 0px 0px 10px 0 rgba($black, 0.02);
    font-size: 15px;
    border: 1px solid $BorderGray;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px $white inset;

      &:first-line {
        font-size: 15px;
      }
    }

    &.mod-focused {
      border-color: $MainCyan !important;
    }
  }
}
