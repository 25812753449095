@import '../../../../styles/media-queries.scss';

.SendFormsAndDocuments-layout {
  padding: 2rem 0;
  flex-direction: column;
  overflow: hidden;

  &.mod-overflow {
    overflow-y: scroll;
  }

  &-children {
    height: 50%;
  }

  &-cta-button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    button {
      width: 240px;
      align-self: center;
      justify-self: center;
    }
  }
}

@include mobile {
  .SendFormsAndDocuments-layout {
    overflow: visible;
  }
}
