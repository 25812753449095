@import '../../../../../styles/AppDesign.scss';
@import '../../../../../styles/media-queries.scss';

.CreateAppointment-select-date-container {
  flex: 1;
  padding: 2rem;
  overflow-y: scroll;
  box-shadow: $GenericInsetBoxShadow;
  margin-top: 2rem;

  &-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input,
  select,
  textarea,
  .InputRtfTextareaField-editor {
    border-color: $NavGray;
    margin-bottom: 1.5rem;
  }

  .InputRtfTextareaField-editor {
    height: 180px;
    font-family: $font-family;
  }
}

@include mobile {
  .CreateAppointment-select-date-container {
    padding: 1rem;
  }
}
