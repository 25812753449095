.TableauWidget {
  width: 100%;
  > div {
    width: 100%;

    iframe {
      width: inherit;
    }
  }
}
