@import '../../../../../styles/AppDesign.scss';
@import '../../../../../styles/media-queries.scss';

.SendFormsAndDocuments-select-document {
  &-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &-container {
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
    flex: 1;
    overflow: hidden;
  }

  &-document {
    &-list {
      display: flex;
      flex-direction: column;
      width: 50%;
      overflow-y: hidden;
      height: 100%;
      padding: 0 2rem;

      input {
        border-color: $NavGray;
        box-shadow: $GenericBoxShadow;
      }

      .documents-container {
        margin-top: 1rem;
        border: 1px solid $NavGray;
        flex: 1;
        border-radius: 10px;
        overflow-y: scroll;
        box-shadow: $GenericInsetBoxShadow;

        .no-forms-content {
          padding: 1rem 0;
          text-align: center;
          color: $LightGray;
        }

        .document {
          display: flex;
          align-items: center;
          padding: 1.25rem 1.5rem;
          border-radius: 10px;
          background: $UnselectedCyan;
          margin-bottom: 1rem;
          font-weight: bold;
          border: 1px solid $NavGray;
          cursor: pointer;

          &.mod-selected {
            background: $SelectedCyan;
            color: $white;
          }
        }
      }
    }

    &-preview {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      margin-right: 2rem;
      border-radius: 10px;
      overflow: scroll;
      background: $NavGray;
      position: relative;

      > span {
        position: absolute;
      }

      .preview-container {
        transform: scale(1.01);
        text-align: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include mobile {
  .SendFormsAndDocuments-select-document {
    &-container {
      flex-direction: column;
      align-items: center;
      padding: 0 1rem;
    }

    &-document {
      &-preview,
      &-list {
        height: 500px;
      }

      &-list {
        width: 100%;
        margin-bottom: 1rem;
      }

      &-preview {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
