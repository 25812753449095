@import '../../../../../styles/AppDesign.scss';
@import '../../../../../styles/media-queries.scss';

.CreateAppointment-completed-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: unset;

  &-icon {
    width: 10rem;
    height: 10rem;
    background-color: $MainCyan;
  }

  &-content {
    font-weight: bold;
    font-size: 24px;
    width: 400px;
    text-align: center;
    line-height: 1.5;
  }

  button {
    margin: 2rem 0;
    width: 250px;
  }
}

@include mobile {
  .CreateAppointment-completed-container {
    padding: 0 1rem;
    &-content {
      width: 100%;
      font-size: 1rem;
    }
  }
}
