@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.SSPUsersTable {
  flex-direction: column;
  overflow: hidden;

  &-header {
    padding: 0 2rem;
    padding-top: 0.75rem;
    padding-bottom: 2rem;

    display: flex;
    align-items: center;

    &-search-bar {
      align-self: center;
      flex: 1;
      border-radius: 10px;
      box-shadow: $GenericSoftBoxShadow;

      input {
        border-color: $UnselectedCyan;
      }
    }

    &-reset-container {
      padding-left: 3rem;
      display: flex;
      align-items: center;

      &-text {
        line-height: 28px;
        font-size: 14px;
        color: $TextDarkerGray;
        white-space: nowrap;
      }

      &-refresh-button {
        margin-left: 20px;
        width: 150px;
        font-size: 14px;
      }
    }
  }

  &-table {
    &-email {
      user-select: text;
    }

    &-role {
      user-select: text;
      text-transform: capitalize;
      color: $TextDarkerGray;
    }

    &-lastLogin {
      user-select: text;
      color: $TextDarkerGray;
      font-size: 0.9rem;
    }

    &-more-icon {
      display: inline-block;
      width: 60px;
      height: 56px;
      margin-right: -1rem;
      mask: url('/assets/icons/trash-2.svg') no-repeat center;
      mask-size: 18px;
      background-color: $TextGray;
      cursor: pointer;
      transition: transform 200ms linear;

      &:hover,
      &.mod-active {
        background-color: $TextColor;
        mask: url('/assets/icons/trash.svg') no-repeat center;
        mask-size: 18px;
      }
    }
  }
}

@include tablet {
  .SSPUsersTable {
    &-header {
      padding: 1rem;
      flex-wrap: wrap;

      &-reset-container {
        padding-top: 1rem;
        padding-left: 0;
        width: 100%;
        display: flex;

        &-text {
          &:nth-of-type(2) {
            padding-right: 20px;
          }
        }

        .cta-button {
          margin-left: auto;
        }
      }
    }
  }
}
