@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';

.TopBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 2%;

  &-current-user-container {
    position: relative;
  }

  &-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &-picture {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background-color: $TextGray;
    }

    &-name {
      max-width: 400px;
      font-size: 15px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 1rem;
      padding-right: 0.5rem;
      border-right: solid 1px $SubTextColor;
    }

    &-role {
      font-size: 15px;
      color: $TextDarkerGray;
      padding-left: 0.5rem;
      padding-right: 1rem;
      text-transform: capitalize;
    }

    &-dropdown-icon {
      width: 10px;
      height: 10px;
      mask: url('/assets/icons/dropdown_arrow.svg') no-repeat center;
      mask-size: contain;
      background-color: $TextDarkerGray;
    }
  }

  &-user-dropdown-menu {
    position: absolute;
    z-index: 999;
    right: 0px;
    width: auto;
    min-width: 200px;
    height: auto;
    margin-top: 2%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.4) 0px 6px 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &-item {
      width: 100%;
      padding: 14px 20px;
      box-sizing: border-box;
      color: $TextGray;
      font-size: 14px;
      font-weight: 600;
      text-align: right;
      border: solid 1px $bgGray;
      border-right: none;
      border-left: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      &:hover,
      &.mod-active {
        background-color: rgba($MainCyan, 0.1);
        color: $MainCyan;
      }

      &-icon {
        width: 14px;
        height: 20px;
        margin-left: 14px;
        background-color: $TextDarkerGray;
      }
    }
  }
}

@include mobile {
  .TopBar {
    justify-content: unset;
    max-width: 100%;

    &-current-user-container {
      width: 100%;
    }

    &-user {
      justify-content: flex-end;

      &-picture {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
