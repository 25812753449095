@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.Stepper {
  width: 100%;
  .MuiStepIcon-root {
    width: 2rem;
    height: 2rem;

    &.Mui-active {
      color: $MainCyan;
    }
    &.Mui-completed {
      color: $MainLightCyan;
    }
  }
  .MuiStepLabel-label {
    font-size: 1rem;

    &.Mui-active {
      color: $TextColor;
      font-weight: bold;
    }
  }
}

@include mobile {
  .Stepper {
    &-mobile-label {
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
      font-weight: bold;
      width: 100%;
      text-align: center;
    }

    .MuiStepLabel-label {
      display: none;
    }
  }
}
