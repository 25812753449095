@import '~styles/AppDesign.scss';

.DMEPOSPaymentResponsibilityAgreementForm {
  p {
    line-height: 1.8;
  }

  &-inline-field {
    &:not(:first-child) {
      margin-left: 8px;
    }
    &:not(:last-child) {
      margin-right: 8px;
    }

    &,
    & > * {
      display: inline !important;
    }

    select,
    input {
      width: 150px !important;
      height: 34px;
    }

    &.declare-length {
      input {
        width: 70px !important;
      }
    }
  }
}
