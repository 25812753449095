@import '../../../../styles/AppDesign.scss';
@import '../../../../styles/media-queries.scss';
@import '~react-big-calendar/lib/sass/styles';

$segment-width: math.div(1, 7) !important !default;

.Appointments {
  overflow-y: scroll;
  position: relative;

  &-loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background-color: rgba($white, 0.5);
    cursor: wait;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .rbc {
      &-calendar {
        padding: 1rem;
        padding-top: 2.5rem;

        * {
          font-family: $font-family !important;
        }

        width: 100%;
      }

      &-month-view,
      &-time-view {
        border-radius: 5px;
      }
      &-today {
        background-color: rgba($MainCyan, 0.175);
      }

      &-off-range-bg {
        background-color: $BorderGray;
      }

      &-toolbar {
        margin-bottom: 1.5rem;

        &-label {
          font-size: 1.5rem;
          font-weight: bold;
          color: $TextColor;
        }
      }

      &-event {
        background-color: $MainCyan;
        border: none;
      }

      &-events-container {
        margin-right: 0;
      }

      &-btn-group {
        box-shadow: $GenericBoxShadow;
        border: 1px solid $UnselectedCyan;
        border-radius: 5px;

        button {
          background-color: rgba($MainCyan, 0.1);
          color: $MainCyan;
          border: none;
          padding: 0.5rem 1rem;
          cursor: pointer;

          &.rbc-active {
            background-color: $MainCyan;
            color: $white;
            box-shadow: none;
          }
        }
      }
    }
  }
}

@include tablet {
  .Appointments {
    &-container {
      .rbc {
        &-calendar {
          padding-top: 1rem;
        }

        &-toolbar {
          &-label {
            width: 100%;
          }
        }
        &-btn-group {
          &:first-of-type {
            margin-top: 1rem;
            margin-bottom: 2rem;
          }

          &:last-of-type {
            margin-top: 1.5rem;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
