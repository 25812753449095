@import '../../../../../../styles/AppDesign.scss';
@import '../../../../../../styles/media-queries.scss';

.SSPUserDetail {
  flex-direction: column;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  &-loading {
    justify-content: center;
    align-items: center;
  }
}
